import { Options } from '@vechain/connex'
import bent from 'bent'
import { Stats } from './types/Stats'
import { ExtendedOptions } from './types/ExtendedOptions'

const getJSON = bent('json', 'GET')
const stats: Stats = { lastWorkingNode: 'unknown' }

export async function getConnex (opts: ExtendedOptions): Promise<Connex> {
  const nodes = typeof opts.node === 'string' ? [opts.node] : opts.node
  const sortedNodes = sortLastWorkingNodeFirst(nodes, stats)

  for (const node of sortedNodes) {
    try {
      const block = await verifyNodeAvailabilityByReadingGenesisBlock(node)
      stats.lastWorkingNode = node
      return await createConnexForNodeOrBrowser({
        ...opts,
        node,
        network: typeof opts.network === 'undefined' ? block : opts.network
      })
    } catch (err) {
      /** console.warn('getConnex():', 'skipping unavailable node', node) */
    }
  }

  throw new Error('getConnex(): no available node found')
}

async function createConnexForNodeOrBrowser (opts: Options): Promise<Connex> {
  // node detection by https://github.com/flexdinesh/browser-or-node/blob/master/src/index.js
  const isNode = typeof process !== 'undefined' && process.versions != null && process.versions.node != null

  if (isNode) {
    return await createConnexForNodeJs(opts)
  }

  return await createConnexForBrowser(opts)
}

async function createConnexForNodeJs (opts: Options): Promise<Connex> {
  const { Framework } = await import('@vechain/connex-framework')
  const { Driver, SimpleNet } = await import('@vechain/connex-driver')

  const driver = await Driver.connect(new SimpleNet(opts.node))
  return new Framework(driver)
}

async function createConnexForBrowser (opts: Options): Promise<Connex> {
  const { Connex } = await import('@vechain/connex')
  return new Connex(opts)
}

async function verifyNodeAvailabilityByReadingGenesisBlock (node: string): Promise<Connex.Thor.Block> {
  // verify by requesting genesis block and that an id is returned
  const block: Connex.Thor.Block = await getJSON(`${node}/blocks/0`)
  if (block.id.slice(0, 2) !== '0x') {
    throw new Error('could not fetch genesis block to obtain node availability')
  }

  return block
}

function sortLastWorkingNodeFirst (nodes: string[], stats: Stats): string[] {
  const previousWorkingNodeIndex = nodes.indexOf(stats.lastWorkingNode)

  // -1 (not found) or 0 (already first) requires no change
  if (previousWorkingNodeIndex <= 0) {
    return nodes
  }

  // flip first node with last working node
  nodes[previousWorkingNodeIndex] = nodes[0]
  nodes[0] = stats.lastWorkingNode
  return nodes
}
